import Link from 'next/link'
import {
  getTargetFromRichtext,
  getUrlFromRichtext,
} from '../../../utils/url-from-richtext'
import striptags from 'striptags'
import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import PlayIcon from '../../../public/play.svg'
import RightIcon from '../../../public/arrow-right-orange.svg'

type Link247Props = {
  courseTypeCode: string | undefined | null
}

const Link247 = ({courseTypeCode}: Link247Props) => {
  const {t} = useTranslation()
  const is247Enabled = process.env.NEXT_PUBLIC_LINK_247_ENABLED === '1'

  const isCourseTypeCodeValid =
    courseTypeCode === 'livewebinar' ||
    courseTypeCode === 'elearning' ||
    courseTypeCode === 'resourcelibrary'

  return (
    <>
      {isCourseTypeCodeValid && is247Enabled ? (
        <div className="c-training__hero_247">
          <div className="c-training__hero_247-container">
            <div className="c-training__hero_247-title d-flex flex-row">
              <Image src={PlayIcon} alt="play-icon"></Image>
              <p>{t('trainingpage_247_title')}</p>
            </div>

            <p className="c-training__hero_247-subtitle">
              {t('trainingpage_247_subtitle')}
            </p>
            <div className="c-training__hero_247-button d-flex flex-row">
              <Link
                href={`${getUrlFromRichtext(t('trainingpage_247_button'))}`}
                target={getTargetFromRichtext(t('trainingpage_247_button'))}
              >
                {striptags(t('trainingpage_247_button'))}
              </Link>
              <Image src={RightIcon} alt="arrow-right"></Image>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Link247
