// eslint-disable-next-line import/prefer-default-export
export const BookingPages = {
  PAY: 'booking_url_pay',
  INVOICEDETAILS: 'booking_url_invoicedetails',
  CHOOSESESSION: 'booking_url_choosesession',
  PERSONALDETAILS: 'booking_url_personaldetails',
}

export const BookingPagesSubscription = {
  PAY: 'booking_url_pay',
  INVOICEDETAILS: 'booking_url_invoicedetails',
  CHOOSESESSION: 'booking_url_choosesession_subscription',
  PERSONALDETAILS: 'booking_url_personaldetails',
}

export const BookingPagesSubscriptionTryout = {
  PAY: 'booking_url_pay',
  INVOICEDETAILS: 'booking_url_invoicedetails',
  CHOOSESESSION: 'booking_url_choosesession_subscription_tryout',
  PERSONALDETAILS: 'booking_url_personaldetails',
}

export const BookingPagesMainUrl = {
  'nl-be': 'boeking',
  'fr-be': 'reservation',
  'fr-lu': 'reservation',
  'fr-fr': 'reservation',
  'en-gb': 'booking',
  'de-de': 'buchung',
  'fi-fi': 'varaus',
  'es-es': 'reserva',
  'da-dk': 'booking',
  'sv-se': 'bokning',
  'nb-no': 'booking',
  'en-en': 'booking',
  'en-ie': 'booking',
}
