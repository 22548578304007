import type {Response} from '../../../types/response'
import type {SubscriptionPageNode, Node} from '../../../types/content-types'
import Hero from '../../shared/TrainingSubscriptionHero'

type Props = {
  page?: Node | null
  response?: Response | null
  children?: React.ReactNode
  linkedSubscriptionTryoutPageId?: string | null
}

const Subscription = ({
  page: incomingPage,
  response,
  children,
  linkedSubscriptionTryoutPageId,
}: Props) => {
  const page = incomingPage as SubscriptionPageNode | null | undefined
  if (!page) {
    return null
  }
  const linkedTrainings = response?.included?.filter(el =>
    page?.relationships.field_language_references?.data.find(
      linkedTraining => linkedTraining.id === el.id,
    ),
  )
  return (
    <div className="c-subscription">
      <Hero
        isTraining={false}
        response={response}
        trainingPage={page}
        linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
        linkedTrainings={linkedTrainings}
      />
      {children}
    </div>
  )
}

export default Subscription
